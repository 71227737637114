import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  AuthTokenResponse,
  ILoginApiResponse,
} from '@merchant-portal/app/models/login/login.interface';
import { IOtpSend } from '@merchant-portal/app/models/otp/otp.interface';
import { environment } from '@merchant-portal/environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth.service';
import { OTP_EVENT_TYPE } from '../constant/event.type';

@Injectable({
  providedIn: 'root',
})
export class OtpApiService {
  private http = inject(HttpClient);
  private authService = inject(AuthService);
  url = environment.backend_portal_url;

  verifyOtp(otp: string, token: string): Observable<ILoginApiResponse> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.post<ILoginApiResponse>(
      `${this.url}api/v1/auth/otp/verify`,
      { otp },
      { headers },
    );
  }

  resendOtp(obj: IOtpSend): Observable<ILoginApiResponse> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.getTempToken()}`,
      'X-Access-Token': this.authService.getAccessToken() ?? '',
    });
    if (obj.event === OTP_EVENT_TYPE.FORGOT_PASSWORD) {
      return this.http.post<ILoginApiResponse>(
        `${this.url}api/v1/auth/forgot-password`,
        obj,
      );
    }
    return this.http.post<ILoginApiResponse>(
      `${this.url}api/v1/auth/otp/send`,
      obj,
      { headers },
    );
  }

  successVerify(token: string, ipInfo: string): Observable<AuthTokenResponse> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'X-IP-Info': JSON.stringify(ipInfo),
    });
    return this.http.get<AuthTokenResponse>(
      `${this.url}api/v1/users/2fa/token`,
      { headers },
    );
  }
}
