import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class UtilService {
  formatDateToUTCString(date: Date, offset?: number): string {
    const d = new Date();
    const diff = offset ?? 0;
    const offsetHours = Math.floor(diff / 60);
    const offsetMinutes = diff % 60;
    const offsetSeconds = diff % 3600;
    // Convert local time to UTC
    const utcTime = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours() + offsetHours,
      date.getMinutes() + offsetMinutes,
      date.getSeconds() + offsetSeconds,
      date.getMilliseconds(),
    );
    // Create new Date object with adjusted UTC time
    const utcDate = new Date(utcTime);
    // Format the date as "YYYY-MM-DDTHH:mm:ss.sssZ" (UTC time)
    return utcDate.toISOString();
  }

  getRemainingMinutes(targetTime: string): number {
    const targetDate = new Date(targetTime);
    const currentTime = Date.now();
    const differenceInMilliseconds = targetDate.getTime() - currentTime;
    const totalMinutes = Math.ceil(differenceInMilliseconds / (1000 * 60));

    return totalMinutes;
  }

  parseSuspendedTime(errorMessage: string): string | null {
    const suspendedMessage =
      'your otp request is currently suspended. please try again after';
    const suspendedTimeIndex = errorMessage.indexOf(suspendedMessage);
    if (suspendedTimeIndex !== -1) {
      const startTimeIndex = suspendedTimeIndex + suspendedMessage.length + 1;
      const suspendedTime = errorMessage.substring(
        startTimeIndex,
        startTimeIndex + 19,
      );
      return suspendedTime;
    }
    return null;
  }

  deleteUndefinedKey<T>(obj: T): T {
    const mappedObj: Record<string, any> = obj as Record<string, any>;
    const result: Record<string, any> = {};
    Object.keys(mappedObj).forEach((key) => {
      if (mappedObj[key]) {
        result[key] = mappedObj[key];
      }
    });
    return result as T;
  }

  getDefaultValue<T>(obj: T, defaultValue: T): T {
    if (obj) {
      return obj;
    }
    return defaultValue;
  }

  safeParseJSON<T>(json: string, defaultValue: T): T {
    try {
      return JSON.parse(json);
    } catch (error) {
      return defaultValue;
    }
  }

  deepCopy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
  }
}
