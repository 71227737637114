import { Injectable } from '@angular/core';
import { environment } from '@merchant-portal/environments/environment';
import { Ajax } from '@newrelic/browser-agent/features/ajax';
import { JSErrors } from '@newrelic/browser-agent/features/jserrors';
import { PageViewEvent } from '@newrelic/browser-agent/features/page_view_event';
import { PageViewTiming } from '@newrelic/browser-agent/features/page_view_timing';
import { SessionTrace } from '@newrelic/browser-agent/features/session_trace';
import { Spa } from '@newrelic/browser-agent/features/spa';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

// Populate using values in copy-paste JavaScript snippet.
const options = {
  init: environment.new_relic.init, // NREUM.init
  info: environment.new_relic.info, // NREUM.info
  loader_config: environment.new_relic.loader_config, // NREUM.loader_config
  features: [JSErrors, Spa, PageViewEvent, PageViewTiming, SessionTrace, Ajax],
};

@Injectable({
  providedIn: 'root',
})
export class NewRelicAgentService {
  browserAgent?: BrowserAgent;

  constructor() {
    if (environment.send_new_relic) {
      this.browserAgent = new BrowserAgent(options);
    }
  }

  handleError(error: any, attribute?: any) {
    if (this.browserAgent) {
      console.error(error);
      this.browserAgent?.noticeError(error, attribute);
    }
  }
}
