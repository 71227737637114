import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DialogComponentService } from '@merchant-portal/app/components/dialog/dialog.component.service';
import { UtilService } from '@merchant-portal/app/services/util.service';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, from } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import {
  ILoginApiResponse,
  ILoginSubmit,
} from '../../../models/login/login.interface';
import { AuthService } from '../../auth.service';
import { FingerprintService } from '../../fingerprint/fingerprint.service';
import {
  LoginParams,
  LoginState,
  LoginStoreFactory,
} from '../factory/login-store.factory';

const initialState: LoginState = {
  errorMessage: '',
  isOtpRequired: false,
  isCountDownStarted: false,
  isDeviceRecognized: false,
};

@Injectable()
export class LoginStoreService
  extends ComponentStore<LoginState>
  implements LoginStoreFactory
{
  constructor(
    private authService: AuthService,
    private router: Router,
    private dialogComponentService: DialogComponentService,
    private utilService: UtilService,
    private fingerprintService: FingerprintService,
  ) {
    super(initialState);
  }

  errorMessage$ = this.select((state) => state.errorMessage);
  isOtpRequired$ = this.select((state) => state.isOtpRequired);
  isCountDownStarted$ = this.select((state) => state.isCountDownStarted);
  isDeviceRecognized$ = this.select((state) => state.isDeviceRecognized);
  vm$ = this.select({
    errorMessage: this.errorMessage$,
    isOtpRequired: this.isOtpRequired$,
    isCountDownStarted: this.isCountDownStarted$,
    isDeviceRecognized: this.isDeviceRecognized$,
  });

  readonly login = this.effect<LoginParams>((loginParams$) =>
    loginParams$.pipe(
      switchMap(({ email, password, isRemember }) =>
        from(this.fingerprintService.getFingerprint()).pipe(
          switchMap((fingerprint: string) => {
            const loginForm: ILoginSubmit = { email, password, isRemember };
            return this.authService.login(loginForm, fingerprint).pipe(
              tap((res: ILoginApiResponse) => {
                this.authService.setFingerPrintId(email, fingerprint);
                if (isRemember) {
                  this.authService.setIsRemember();
                }
                if (res?.data?.accessToken) {
                  this.patchState({ isDeviceRecognized: true });
                  this.authService.setAccessToken(res.data.accessToken || '');
                  this.checkAccessToken();
                  if (res.data.userInfo) {
                    this.authService.setUserInfo(res.data.userInfo);
                    this.authService.setRefreshToken(
                      res.data.refreshToken || '',
                    );
                  }
                } else if (res?.data?.token) {
                  this.patchState({
                    isOtpRequired: true,
                    errorMessage: '',
                    isCountDownStarted: true,
                  });
                  this.authService.setTempToken(res.data.token);
                  this.authService.setEmail(email);
                } else {
                  this.patchState({
                    isOtpRequired: true,
                    errorMessage: '',
                    isCountDownStarted: true,
                  });
                  this.authService.setTempToken(
                    res.data.token ?? res.data.accessToken,
                  );
                  this.authService.setEmail(email);
                }
              }),
              catchError(({ error }: HttpErrorResponse) => {
                if (error.errors.includes('suspended')) {
                  const suspendedTime = this.utilService.parseSuspendedTime(
                    error.errors,
                  );
                  if (suspendedTime) {
                    const remainingMinutes =
                      this.utilService.getRemainingMinutes(suspendedTime);
                    this.showSuspendedDialog(remainingMinutes);
                  }
                } else if (error.errors.includes('please wait')) {
                  this.patchState({
                    isOtpRequired: false,
                    errorMessage: error.errors,
                    isCountDownStarted: false,
                  });
                } else {
                  this.patchState({ errorMessage: error.errors });
                }
                return EMPTY;
              }),
            );
          }),
        ),
      ),
    ),
  );

  showSuspendedDialog(suspendedTime: number) {
    this.dialogComponentService.openConfirmationDialog({
      width: '530px',
      height: '275px',
      closable: true,
      body: {
        title: 'Code Request Suspended',
        message: `Your code verification request is currently suspended.<br>Please try again after ${suspendedTime} minutes.`,
        closeText: 'Close',
        confirmText: 'Contact Helpdesk',
        isDoNothing: true,
      },
    });
  }

  disableOtpRequire = () => {
    this.patchState({ isOtpRequired: false });
  };

  checkAccessToken() {
    if (this.authService.getAccessToken()) {
      this.router.navigateByUrl('/dashboard');
    }
  }

  getFingerPrintId(email: string) {
    return this.authService.getFingerPrintId(email) || '';
  }

  readonly checkStoredFingerprint = this.effect<string>((trigger$) =>
    trigger$.pipe(
      switchMap((email) =>
        from(this.fingerprintService.getFingerprint()).pipe(
          tap((currentFingerprint) => {
            const storedFingerprint = this.getFingerPrintId(email);
            if (storedFingerprint && storedFingerprint === currentFingerprint) {
              this.patchState({ isDeviceRecognized: true });
            } else {
              this.patchState({ isDeviceRecognized: false });
            }
          }),
        ),
      ),
    ),
  );
}
