export enum TRANSACTION_TYPE {
  DISBURSEMENT_TOP_UP = 'DISBURSEMENT_TOP_UP',
  PAYMENT = 'PAYMENT',
  DISBURSEMENT = 'DISBURSEMENT',
  BULK_DISBURSEMENT = 'BULK_DISBURSEMENT',
  PAYMENT_CREDIT_CARD = 'PAYMENT_CREDIT_CARD',
  WITHDRAWAL = 'WITHDRAWAL',
  DISBURSEMENT_WITHDRAWAL = 'DISBURSEMENT_WITHDRAWAL',
  PAYMENT_WITHDRAWAL = 'PAYMENT_WITHDRAWAL',
  PAYMENT_FEE = 'PAYMENT_FEE',
  DISBURSEMENT_FEE = 'DISBURSEMENT_FEE',
  ACCOUNT_INQUIRY_FEE = 'ACCOUNT_INQUIRY_FEE',
  XB_FEE = 'XB_FEE',
  PLATFORM_ACTIVITY_FEE = 'PLATFORM_ACTIVITY_FEE',
  PLATFORM_TRANSFER_FEE = 'PLATFORM_TRANSFER_FEE',
  PLATFORM_TRANSACTION_FEE = 'PLATFORM_TRANSACTION_FEE',
  MANUAL_ADJUSTMENT = 'MANUAL_ADJUSTMENT',
  MANUAL_TOP_UP = 'MANUAL_TOP_UP',
  BALANCE_ADJUSTMENT = 'BALANCE_ADJUSTMENT',
  BULK = 'BULK',
  SINGLE = 'SINGLE',
}
// DISBURSEMENT, PAYMENT, FEE, MANUAL_ADJUSTMENT;

export const TRANSACTION_TYPE_LABEL: Record<string, string> = {
  [TRANSACTION_TYPE.DISBURSEMENT_TOP_UP]: 'VA Top Up',
  [TRANSACTION_TYPE.PAYMENT]: 'Payment',
  [TRANSACTION_TYPE.DISBURSEMENT]: 'Single Disbursement',
  [TRANSACTION_TYPE.BULK_DISBURSEMENT]: 'Bulk Disbursement',
  [TRANSACTION_TYPE.PAYMENT_CREDIT_CARD]: 'Payment Credit Card',
  [TRANSACTION_TYPE.DISBURSEMENT_WITHDRAWAL]: 'Withdrawal: Payout',
  [TRANSACTION_TYPE.PAYMENT_WITHDRAWAL]: 'Withdrawal: Payment',
  [TRANSACTION_TYPE.PAYMENT_FEE]: 'Payment Fee',
  [TRANSACTION_TYPE.DISBURSEMENT_FEE]: 'Disbursement Fee',
  [TRANSACTION_TYPE.ACCOUNT_INQUIRY_FEE]: 'Account Inquiry Fee',
  [TRANSACTION_TYPE.XB_FEE]: 'Cross Border Fee',
  [TRANSACTION_TYPE.PLATFORM_ACTIVITY_FEE]: 'Platform Activity Fee',
  [TRANSACTION_TYPE.PLATFORM_TRANSFER_FEE]: 'Platform Transfer Fee',
  [TRANSACTION_TYPE.PLATFORM_TRANSACTION_FEE]: 'Platform Transaction Fee',
  [TRANSACTION_TYPE.MANUAL_TOP_UP]: 'Manual Top Up',
  [TRANSACTION_TYPE.BALANCE_ADJUSTMENT]: 'Balance Adjustment',
};

export enum TRANSACTION_STATUS {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum TRANSACTION_CHANNEL {
  MANUAL_TRANSFER = 'MANUAL_TRANSFER',
  BALANCE_ADJUSTMENT = 'BALANCE_ADJUSTMENT',
}

export enum TRANSACTION_BALLANCE_TYPE {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export enum APPROVAL_STATUS {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  WAITING = 'WAITING',
}
